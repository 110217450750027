function NotFoundPage() {
  const navLinks = [
    { text: `Home`, href: `/` },
    { text: `About`, href: `/about/` },
    { text: `Contact`, href: `/contact/` }
  ]

  return (
    <Base>
      <Wrapper>
        <div>
          <header>
            <h1>
              <LargeText>404</LargeText>
              <SmallerText>Oh no! This page doesn't exist.</SmallerText>
            </h1>
          </header>

          <main>
            <section>
              <Text>How about one of these?</Text>
              <Nav>
                <ul>
                  {navLinks.map(link => (
                    <li key={link.href}>
                      <RealLink href={link.href}>{link.text}</RealLink>
                    </li>
                  ))}
                </ul>
              </Nav>
            </section>
          </main>
        </div>
      </Wrapper>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6%;
  height: 100vh;
  text-align: center;
  letter-spacing: -0.025rem;
`

const LargeText = styled.span`
  font-size: 7rem;
  font-weight: 700;
  white-space: nowrap;

  ${media.sm`
    font-size: 9rem;
  `}
`

const SmallerText = styled.span`
  display: block;
  padding-top: var(--s5);
  font-size: var(--f7);
  font-weight: 800;

  ${media.sm`
    font-size: 2.1rem;
  `}
`

const Nav = styled.nav`
  margin-top: var(--s6);
`

const Text = styled.h2`
  margin: 0 auto;
  padding-top: var(--s7);
  max-width: 24rem;
  line-height: var(--lh1);
  font-size: var(--f5);
  font-weight: 400;

  ${media.sm`
    padding-top: var(--s7);
    font-size: var(--f6);
  `}
`

const RealLink = styled(Link)`
  display: block;
  margin-top: var(--s1);
  font-size: var(--f4);
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Base from '../ui/Base'
import { Link } from '../ui/elements'
import { media } from '../styles'

export default NotFoundPage
